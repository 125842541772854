import styled from 'styled-components';

export const PrimaryButton = styled.div`
	padding: 10px 20px;
	border-radius: 10px;
	color: ${({theme, text}) => text?theme.colors[text]:theme.colors.white};
	background-color: ${({theme, background}) => background?theme.colors[background]:theme.colors.green};
	cursor: pointer;
	display: inline-block;
`;

export const UnderlinedButton = styled.div`
	color: ${({theme, text}) => text?theme.colors[text]:theme.colors.blue};
	cursor: pointer;
	text-decoration: underline;
`;