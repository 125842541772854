import {useState, useEffect} from 'react';
import {
	RootContainer,
	Header,
	HeaderText,
	ButtonContainer,
	ContentWrapper,
	SectionTitle,
	LatestPitchContainer,
	VideoContainer,
	QuestionContainer,
	QuestionCard,
	QuestionTitle,
	QuestionTime,
	VideoDetailContainer,
	VideoDetailTextContainer,
	VideoDetailText,
	SmallVideoContainer,
	SmallVideoContainerText,
	VideoThumbnailImage,
	SmallVideoContainerTextWrapper,
} from './Dashboard.styles';
import {PrimaryButton, HorizontalSpace, VerticalSpace} from 'components';
import Carousel from "react-multi-carousel";
import playButton from 'assets/images/play-icon.png'
import "react-multi-carousel/lib/styles.css";
import { oembed } from "@loomhq/loom-embed";
import {userActions} from 'modules/user';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';
import useQuery from 'utils/useQuery';
import { useAuth0 } from "@auth0/auth0-react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Dashboard = () => {
	const navigate = useNavigate()
	const {id} = useParams()
	const query = useQuery()
	const dispatch = useDispatch()
	const {allAnswers, accountId} = useSelector(state => state.user, shallowEqual)
	const [videoHTML, setVideoHTML] = useState("");
	const { user, isAuthenticated, isLoading } = useAuth0();
	const [allAnswersArray, setAllAnswersArray] = useState([]);
	const {profile, answers, action} = useSelector(state => state.user, shallowEqual)

	useEffect(() => {
		if(!accountId)
			navigate('/')
		else
			dispatch(userActions.getDashboardDataAsyncActions.request(accountId))
	}, [accountId])
		
	const getLoomEmbed = async (videoId) => {
		try {
			const { html, thumbnail_url, duration } = await oembed(`https://www.loom.com/share/${videoId}`, {height: window.innerHeight/1.5});
			// console.log("LOOM EMBED", html, thumbnail_url, duration)
			if(html && thumbnail_url && duration)
				return { html, thumbnail_url, duration };
		}
		catch(err) {
			// console.log("OMBED ERROR", err)
			return {html: `<img src="https://cdn.loom.com/assets/marketing/404/404.jpg" >`, thumbnail_url: "https://cdn.loom.com/assets/marketing/404/404.jpg", duration: 1}
		}
	}

	useEffect(async () => {
		if(allAnswers?.length) {
			const {html} = await getLoomEmbed(allAnswers[0]?.videoId);
			// console.log("EMBED URL", html)
			setVideoHTML(html)
		  	const answersArray = allAnswers.map(async answer => {
		  		// console.log("ANSWER", answer)
		  		const {thumbnail_url, duration} = await getLoomEmbed(answer?.videoId);
		  		// console.log("CAROUSAL", {thumbnail_url, duration})

		  		return {...answer, thumbnail_url, duration}
		  	})
		  	// console.log("ANSWERS PROMISE", )
		  	Promise.all(answersArray).then(result => {
		  		  setAllAnswersArray(result)
		  	}) 
		}
	}, [allAnswers])

	return (
		<RootContainer>
			<Header>
				<HeaderText>Hello, {profile?.name}</HeaderText>
				<ButtonContainer>
					<PrimaryButton onClick={() => navigate('/interview')} >Start Practice →</PrimaryButton>
					<HorizontalSpace count={1} />
					<PrimaryButton onClick={() => dispatch(userActions.logoutAction.action())} >Logout</PrimaryButton>
				</ButtonContainer>
			</Header>
			<ContentWrapper>
				<SectionTitle>Latest Video</SectionTitle>
				<VerticalSpace count={3} />
				{
					allAnswers && allAnswers.length
					?
					<LatestPitchContainer>
						<VideoContainer dangerouslySetInnerHTML={{ __html: videoHTML }} ></VideoContainer>
						<QuestionContainer>
							<VideoDetailContainer>
								<VideoDetailTextContainer>
									<VideoDetailText>Recorded on {moment(allAnswers[0]?.createdOn).format("DD MMM YYYY")}</VideoDetailText>
									<VerticalSpace count={2} />
								</VideoDetailTextContainer>
								{
									allAnswers[0]?.objectURL
									?
									<PrimaryButton background="blue" onClick={() => window.open(allAnswers[0]?.objectURL, '_blank')} >Get PPT ⬇</PrimaryButton>
									:
									<PrimaryButton background="black" text="halfWhite" onClick={() => alert("We will notify you once the PPT is available!")} >PPT (Work in progress..)</PrimaryButton>
								}
							</VideoDetailContainer>
							{
								allAnswers?.length
								?
								allAnswers[0]?.answers?.map(answer => (
									<QuestionCard>
										<QuestionTitle>Q{answer.questionNumber}: {answer.question}</QuestionTitle>
										<QuestionTime>{moment.duration(answer.time*1000).minutes()} min {moment.duration(answer.time*1000).seconds()} secs</QuestionTime>
									</QuestionCard>
								))
								:
								"No recording found..."
							}
						</QuestionContainer>
					</LatestPitchContainer>
					:
					<QuestionTitle>No Video to Show</QuestionTitle>
				}
				<VerticalSpace count={5} />
				<SectionTitle>All Videos</SectionTitle>
				<VerticalSpace count={3} />
				{	
					allAnswersArray && allAnswersArray.length
				  	?
					<Carousel
					  swipeable={false}
					  draggable={false}
					  showDots={false}
					  responsive={responsive}
					  infinite={false}
					  autoPlay={false}
					  autoPlaySpeed={1000}
					  keyBoardControl={true}
					  customTransition="all .5"
					  transitionDuration={500}
					  containerClass="carousel-container"
					  removeArrowOnDeviceType={[]}
					  deviceType={"web"}
					  dotListClass="custom-dot-list-style"
					  itemClass="carousel-item-padding-40-px"
					>
					  {
					  	allAnswersArray.map((answer,i) => {
					  		 return (
						  	 	<SmallVideoContainer onClick={() => navigate(`/previousRecording/${answer.videoId}`)} >
						  	 		<VideoThumbnailImage src={answer.thumbnail_url} />
						  	 		<img width="50px" height="50px" src={playButton} style={{zIndex: 100}} />
						  	 		<SmallVideoContainerTextWrapper >
						  	 			<SmallVideoContainerText style={{zIndex: 100}} >Video Pitch #{i+1}</SmallVideoContainerText>
							   			<SmallVideoContainerText style={{zIndex: 100}} >{moment(answer.createdOn).format("DD MMM YYYY hh:mmA")}</SmallVideoContainerText>
							   			<SmallVideoContainerText style={{zIndex: 100}} >{moment.duration(answer.duration*1000).minutes()} min {moment.duration(answer.duration*1000).seconds()} secs</SmallVideoContainerText>
						  	 		</SmallVideoContainerTextWrapper>
						  	 	</SmallVideoContainer>
						  	 )
					  	})
					  }
					</Carousel>
					:
					<QuestionTitle>No Videos to Show</QuestionTitle>
				}
			</ContentWrapper>
		</RootContainer>
	)
}

export default Dashboard;
