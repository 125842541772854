import {useState, useEffect} from 'react';
import {
	RootContainer,
	LogoImage,
	BodyContainer,
	WelcomeText,
	MainText,
	TaglineText,
	ArrowImage,
	StartButton,
	NoteText,
	NoteText1,
	LoginContainer,
} from './Opening.styles';
import {HorizontalSpace, VerticalSpace, PrimaryButton, PlainInput, UnderlinedButton} from 'components';
import Logo from 'assets/images/brainsprays_logo400.png';
import Arrow from 'assets/images/down-arrow-png-image.png';
import {detect} from 'detect-browser';
import SquareLoader from 'react-spinners/SquareLoader';
import {useNavigate} from 'react-router-dom';
import {userActions} from 'modules/user';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import useQuery from 'utils/useQuery';
import isMobile from 'ismobilejs'

const Opening = () => {
	const dispatch = useDispatch();
	const query = useQuery()
	const navigate = useNavigate()
	const [isSupported, setIsSupported] = useState(true)
	const [currentScreen, setCurrentScreen] = useState('login');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const {isLoading, accountId, profile} = useSelector(state => state.user)

	const onSubmit = () => {
		if(currentScreen === 'signup')
			dispatch(userActions.signupAsyncActions.request({name, email, password}))
		else
			dispatch(userActions.loginAsyncActions.request({email, password}))
	}

	useEffect(() => {
		const browser = detect()
		if(!(browser.name === "chrome" && parseInt(browser.version) > 90) || isMobile(window.navigator).any) {
			setIsSupported(false)
		}

		const zoomUserId = query.get('id')

		if(zoomUserId) {
			dispatch(userActions.getUserAsyncActions.request({data: zoomUserId, action: query.get('action'), callback: () => navigate('/interview')}))
		}

	}, [])

	useEffect(() => {
		 // console.log("SUER LOADING", accountId)
		if(accountId) {
			navigate('/dashboard')
		}
	}, [accountId])

	if(false) {
		return (
			<RootContainer>
				<BodyContainer>
					<SquareLoader size={35} color="#138DCA" />
				</BodyContainer>
			</RootContainer>
		)
	}

	return (
		<RootContainer>
			<LogoImage src="https://pbs.twimg.com/profile_images/1232727609428996096/IS0hCD5l_400x400.png" />
			<BodyContainer>
				<WelcomeText>Welcome to Comprehend!</WelcomeText>
				<VerticalSpace count={2} />
				<MainText>Reading comprehension practice</MainText>
				<ArrowImage src={Arrow} />
				{
					isSupported
					?
					isLoading
					?
					<SquareLoader size={35} color="#138DCA" />
					:
					<LoginContainer>
						<MainText>{currentScreen === "signup"?"Create Your Account":"Welcome Back, Login!"}</MainText>
						{
							currentScreen === "signup"
							?
							<PlainInput placeholder="Name" onChange={(evt) => setName(evt.target.value)} />
							:
							null
						}
						<PlainInput placeholder="Email" type="email" name="email" onChange={(evt) => setEmail(evt.target.value)} />
						<PlainInput placeholder="Password" type="password" name="password" onChange={(evt) => setPassword(evt.target.value)} type="password" />
						<PrimaryButton onClick={onSubmit} >Start →</PrimaryButton>
						<VerticalSpace count={2} />
						{
							isSupported && isLoading && isMobile(window.navigator)
							?
							<SquareLoader size={35} color="#138DCA" />
							:
							currentScreen === "signup"
							?
							<UnderlinedButton onClick={() => setCurrentScreen("login")} >Already have an account? Sign In</UnderlinedButton>
							:
							<UnderlinedButton onClick={() => setCurrentScreen("signup")} >Don’t have an account? Sign Up</UnderlinedButton>
						}
					</LoginContainer>
					:
					<NoteText danger >We currently support desktop version of Google Chrome v90+ browsers only &#128578;. Sorry for the inconvinience!</NoteText>
				}
				<VerticalSpace count={1} />
				<NoteText>By clicking start, you agree to <a href="https://prepair.ai/privacy" target="_blank">Privacy Policy</a> and the <a href="https://prepair.ai/terms-of-use" target="_blank">Terms and Conditions</a>.</NoteText>
				<NoteText1>Note - Our app does not work in Chrome Incognito Window!</NoteText1>
			</BodyContainer>
		</RootContainer>
	)
}

export default Opening;