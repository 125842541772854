import {useState, useEffect} from 'react';
import {
  RootContainer,
  LogoImage,
  BodyContainer,
  WelcomeText,
  MainText,
  TaglineText,
  ArrowImage,
  StartButton,
  NoteText,
  TextInput,
  UrlInputContainer,
  UrlInput,
} from './PostTest.styles';
import Select from "react-dropdown-select";
import StarRatingComponent from "react-star-rating-component";
import Logo from 'assets/images/brainsprays_logo400.png';
import {PrimaryButton, VerticalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import validate from 'validate.js';
import {useNavigate} from 'react-router-dom';
import {Duration, DateTime} from 'luxon';
import useQuery from 'utils/useQuery';
import {userActions} from 'modules/user';
import axios from 'axios';
import industries from './industries.js';
import SquareLoader from 'react-spinners/SquareLoader';

export const constraints = {
  email: {
    presence: { 
    	allowEmpty: false,
    	message: '^You cannot leave this field empty'
    },
    email: {
    	message: '^This doesn\'t look like a valid email for us. Please re-check' 
    },
  },
  landingPage: {
    presence: { 
    	allowEmpty: false,
    	message: '^You cannot leave this field empty' 
    }
  },
  industry: {
    presence: { 
    	allowEmpty: false,
    	message: '^You cannot leave this field empty' 
    }
  },
};

const PostTest = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	const {profile, accountId, answers, action} = useSelector(state => state.user, shallowEqual)

	const [email, setEmail] = useState('');
	const [landingPage, setLandingPage] = useState('');
	const [ratings, setRatings] = useState(5);
	const [industry, setIndustry] = useState(industries[0].name);
	const [error, setError] = useState({});

	const urlChecker = async (landingPage) => {
		try {
			const landingPageData = await axios.get(landingPage, {headers: {"Access-Control-Allow-Origin": "*"}})
			console.log("landingPageData", landingPageData)
		}
		catch(err) {
			console.log("WRONG URL", err)
		}
	}

	const onDone = async () => {}

	useEffect(() => {
		const videoId = query.get('videoId')
		dispatch(userActions.saveAnswerAsyncActions.request({data: {answeredBy: accountId, videoId, createdOn: DateTime.now().toISO()}, callback: () => navigate('/thanks')}))
	}, [])

	return (
		<RootContainer>
			<LogoImage src={Logo} />
        	<BodyContainer>
        		<SquareLoader size={35} color="#138DCA" />
        	</BodyContainer>
		</RootContainer>
	)
}

export default PostTest;