import {useState, useEffect} from 'react';
import {
	RootContainer,
	LogoImage,
	BodyContainer,
	MainText,
	TaglineText,
	GifImage,
} from './Thanks.styles';
import Logo from 'assets/images/brainsprays_logo400.png';
// import GIF from 'assets/images/leonardo-dicaprio.gif';
import {PrimaryButton, VerticalSpace} from 'components';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import {useParams, useNavigate} from 'react-router-dom';

const Thanks = () => {
	const [showConfetti, setShowConfetti] = useState(true)
	const navigateToDashboard = useNavigate();

	useEffect(() => {
		setTimeout(() => setShowConfetti(false), 3000)
	}, [])

	return (
		<RootContainer>
			{showConfetti && <ConfettiExplosion duration={5000} force={0.6} particleCount={200} floorHeight={1600} floorWidth={1600} />}			
			<LogoImage src={Logo} />
			<BodyContainer>
				<MainText>Congrats, All done! 🎉</MainText>
				<TaglineText>Your answers will be reviewed and we will get back to you with feedback in 3 - 5 days.</TaglineText>
				<VerticalSpace count={4} />
				{/*<GifImage src={GIF} />*/}
				<GifImage loading="lazy" src="https://media1.giphy.com/media/vDO1djCs4urEk/giphy.gif" alt="success" />
				<VerticalSpace count={3} />
				<PrimaryButton onClick={() => navigateToDashboard('/dashboard')} >Go to dashboard</PrimaryButton>
			</BodyContainer>
		</RootContainer>
	)
}

export default Thanks;