import styled from 'styled-components'
import {PrimaryButton} from 'components';

export const RootContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const LogoImage = styled.img`
	height: 100px;
	width: auto;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const WelcomeText = styled.div`
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: #333;
`;

export const WelcomeTextFuture = styled.h1`
	margin: 0px;
	color: #333;
`;

export const MainText = styled.h2`
	margin: 0px;
	color: #333;
`;

export const TaglineText = styled.div`
	font-size: 1.2rem;
	line-height: 2.25rem;
	color: #333;
`;

export const NoteText = styled.p`
	color: #333;
`;

export const ArrowImage = styled.img`
	height: 100px;
	width: 60px;
	opacity: 0.7;
`;

export const StartButton = styled(PrimaryButton)`
	display: ${({isVisible}) => isVisible?"block":"none"};
`;

export const TextImage = styled.img`
	width: 60%;
	height: auto;
	align-self: center;
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: center;
`;