import {useState, useEffect} from 'react';
import {PrimaryButton, VerticalSpace, HorizontalSpace} from 'components';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';

import {
	RootContainer,
	ParticipantWrapper,
	ParticipantContainer,
	ParticipantEmail,
	ParticipantName,
	InstructionWrapper,
	InstructionsHeading,
	InstructionsContainer,
	InstructionList,
	InstructionListItem,
	ButtonBar,
} from './Instructions.styles';

const Instructions = () => {
	const navigate = useNavigate()
	const {profile, accountId, action} = useSelector(state => state.user, shallowEqual)

	useEffect(() => {
		if(!accountId) {
		  navigate('/')
		}
	}, [])

	if(action === 'futureself') {
		return (
			<RootContainer>
				<ParticipantWrapper>
					<ParticipantContainer>
						<ParticipantName>
							Welcome, {profile.name}
						</ParticipantName>
					</ParticipantContainer>
				</ParticipantWrapper>
				<VerticalSpace count={2} />
				<InstructionWrapper>
					<InstructionsHeading>Pitch Your Future Self (Dec 31, 2022) - Instructions</InstructionsHeading>
					<InstructionsContainer>
						<InstructionList>
							<InstructionListItem>
								2 questions and 5 mins max.
							</InstructionListItem>
							<InstructionListItem>
								<b>Answer questions as if it’s December 31st 2022.</b> Assume you’ve hit your most important goals and incorporate those milestones into your responses.
							</InstructionListItem>							
							<InstructionListItem>
								Questions will not auto-play. Press the play button (▶️).
							</InstructionListItem>
							<InstructionListItem>
								Start answering immediately after each question so that it's spontaneous.
							</InstructionListItem>
							<InstructionListItem>
								We will let you know when to stop the recording. Please don't stop/pause until we say so. 
							</InstructionListItem>
							<InstructionListItem>
								You will get to preview the recording before saving. So don't worry!
							</InstructionListItem>
							<InstructionListItem>
								Please don't refresh the page! Your recording will not be saved to your account.
							</InstructionListItem>
						</InstructionList>
					</InstructionsContainer>
					<ButtonBar>
				{/*		<PrimaryButton background="red" >No, I'm out of this</PrimaryButton>
						<HorizontalSpace count={2} />*/}
						<PrimaryButton onClick={() => navigate('/interview')} >Proceed</PrimaryButton>
					</ButtonBar>
				</InstructionWrapper>
			</RootContainer>
		)
	}

	return (
		<RootContainer>
			<ParticipantWrapper>
				<ParticipantContainer>
					<ParticipantName>
						Welcome, {profile.name}
					</ParticipantName>
				</ParticipantContainer>
			</ParticipantWrapper>
			<VerticalSpace count={2} />
			<InstructionWrapper>
				<InstructionsHeading>Startup Pitch Practice - Instructions</InstructionsHeading>
				<InstructionsContainer>
					<InstructionList>
						<InstructionListItem>
							4 questions and 5 mins max.
						</InstructionListItem>
						<InstructionListItem>
							Questions will not auto-play. Press the play button (▶️).
						</InstructionListItem>
						<InstructionListItem>
							Start answering immediately after each question so that it's spontaneous.
						</InstructionListItem>
						<InstructionListItem>
							We will let you know when to stop the recording. Please don't stop/pause until we say so. 
						</InstructionListItem>
						<InstructionListItem>
							You will get to preview the recording before saving. So don't worry!
						</InstructionListItem>
						<InstructionListItem>
							Please don't refresh the page! Your recording will not be saved to your account.
						</InstructionListItem>
						<InstructionListItem>
							That's all you need to get started. <b>Good luck!</b>
						</InstructionListItem>
					</InstructionList>
				</InstructionsContainer>
				<ButtonBar>
			{/*		<PrimaryButton background="red" >No, I'm out of this</PrimaryButton>
					<HorizontalSpace count={2} />*/}
					<PrimaryButton onClick={() => navigate('/interview')} >Proceed</PrimaryButton>
				</ButtonBar>
			</InstructionWrapper>
		</RootContainer>
	)
}

export default Instructions;
