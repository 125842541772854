import {call, put, select, takeLeading} from 'redux-saga/effects';
import {
	getUserAsyncActions,
	saveLinkedInAnswerAsyncActions,
	saveAnswerAsyncActions,
	getAllAnswersAsyncActions,
	getDashboardDataAsyncActions,
	signupAsyncActions,
	loginAsyncActions
} from './actions';
import {
	getUser,
	saveAnswer,
	getAllAnswers,
	getPresentation,
	saveUser,
	signup,
	login
} from './api';
import {toast} from 'react-toastify';

function* getUserSaga(action) {
	try{
		const response = yield call(getUser, action.payload.data)
		yield put(getUserAsyncActions.success(response.data.result))
		action.payload.callback()
	}
	catch(err) {
		toast.error("Something went wrong. Changes not saved 😔. Please go back to PrepAir chatbot and click on the link again!")
		yield put(getUserAsyncActions.error())
	}
}

function* getDashboardDataSaga(action) {
	try {
		// console.log("SAVE USER RESPONSE", userResponse.data, action)
		const response = yield call(getAllAnswers, action.payload)
		yield put(getAllAnswersAsyncActions.success(response.data.result))
	}
	catch{
		toast.dismiss()
		toast.error("Something went wrong. Changes not saved 😔.")
	}
}

function* saveLinkedInAnswerSaga(action) {
	try{
		toast.success("Saving your answers (in progress)...", {autoClose: false})
		const {answers} = yield select(state => state.user)
		const actionCategory = yield select(state => state.user.action)
		const userResponse = yield call(saveUser, action.payload.data)
		const response = yield call(saveAnswer, {...action.payload.data, answeredBy: userResponse.data.result._id, answers, source: 'prepair', action: actionCategory || 'pitchdeck', landingPage: action.payload.data.landingPage, email: action.payload.data.email, ratings: action.payload.data.ratings})
		const presentationResponse = yield call(getPresentation, {id: action.payload.data.videoId})
		yield put(saveLinkedInAnswerAsyncActions.success(response.data.result))
		toast.dismiss()
		action.payload.callback()
	}
	catch(err) {
		toast.dismiss()
		toast.error("Something went wrong. Changes not saved 😔.")
		yield put(getUserAsyncActions.error())
	}
}

function* getAllAnswersSaga(action) {
	try{
		const response = yield call(getAllAnswers, action.payload.data, action.payload.action)
		yield put(getAllAnswersAsyncActions.success(response.data.result))
		action.payload.callback()
	}
	catch(err) {
		toast.error("Something went wrong. Changes not saved 😔. Please go back to PrepAir chatbot and click on the link again!")
		yield put(getAllAnswersAsyncActions.error())
	}
}

function* saveAnswerSaga(action) {
	try{
		let delay = 10;
		const {answers} = yield select(state => state.user)
		const actionCategory = yield select(state => state.user.action)
		// console.log("ANSWERS FROM SAGA", answers)
		const response = yield call(saveAnswer, {...action.payload.data, answers})
		const presentationResponse = yield call(getPresentation, {id: action.payload.data.videoId})
		yield put(saveAnswerAsyncActions.success(response.data.result))
		action.payload.callback()
		
	}
	catch(err) {
		// console.log("Save Answer Error", err)
		toast.error("Something went wrong. Changes not saved 😔. Please go back to PrepAir chatbot and click on the link again!")
		yield put(saveAnswerAsyncActions.error())
	}
}

function* loginSaga(action) {
	try {
		const response = yield call(login, action.payload)
		yield put(loginAsyncActions.success(response.data.result))
	}
	catch(err) {
		toast.error("Something is not right. Please check the email and password and try again")
		yield put(loginAsyncActions.error(err))
	}
}

function* signupSaga(action) {
	try {
		const response = yield call(signup, action.payload)
		yield put(signupAsyncActions.success(response.data.result))
	}
	catch(err) {
		// console.log(err)
		yield put(signupAsyncActions.error(err.data.message))
	}
}

function* mainSaga() {
	yield takeLeading(getUserAsyncActions.actionNames.request, getUserSaga)
	yield takeLeading(saveAnswerAsyncActions.actionNames.request, saveAnswerSaga)
	yield takeLeading(getAllAnswersAsyncActions.actionNames.request, getAllAnswersSaga)
	yield takeLeading(saveLinkedInAnswerAsyncActions.actionNames.request, saveLinkedInAnswerSaga)
	yield takeLeading(getDashboardDataAsyncActions.actionNames.request, getDashboardDataSaga)
	yield takeLeading(loginAsyncActions.actionNames.request, loginSaga)
	yield takeLeading(signupAsyncActions.actionNames.request, signupSaga)
}

export default mainSaga;