import axios from 'axios'

export const getUser = (id) => {
	return axios.get(`/getAccount/${id}`)
}

export const login = (data) => {
	return axios.post('/login', {...data})
}

export const signup = (data) => {
	return axios.post('/signup', {...data})
}

export const saveAnswer = (data) => {
	return axios.post(`/saveAnswer`, {...data})
}

export const getPresentation = (data) => {
	return axios.get(`https://bs9gswa1q9.execute-api.ap-south-1.amazonaws.com/mcqv1/mcqs-pptx?videoId=${data.id}&collection=comprehendanswers`)
}

export const getAllAnswers = (id, action) => {
	return axios.get(`/getAllAnswers/${id}?source=prepair`)
}

export const saveUser = (data) => {
	return axios.post(`/saveAccount`, {accountId: data.id, name: data.name, email: data.email})
} 