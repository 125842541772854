import { useEffect, useState, useRef } from "react";
import {
  RootContainer,
  LogoImage,
  BodyContainer,
  WelcomeText,
  WelcomeTextFuture,
  MainText,
  TaglineText,
  ArrowImage,
  StartButton,
  NoteText,
  TextImage,
  ButtonRow,
} from './Interview.styles';
import SquareLoader from 'react-spinners/SquareLoader';
import Logo from 'assets/images/brainsprays_logo400.png';
import {useNavigate} from 'react-router-dom';
import {PrimaryButton, VerticalSpace, HorizontalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {Duration, DateTime} from 'luxon';
import { setup, isSupported } from "@loomhq/loom-sdk";
import { oembed } from "@loomhq/loom-embed";
import { Timer, Time, TimerOptions } from 'timer-node';
import {animated, useTransition} from 'react-spring';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAuth0 } from "@auth0/auth0-react";
import stringSimilarityCheck from 'utils/stringSimilarityChecker';

  // const API_KEY = "28bb8238-2572-4a69-a2cc-a1262c0a7df5"; //dev
  const API_KEY = "5fd94efe-f454-4779-a372-bfc67b36a99b"; //prd
  const BUTTON_ID = "loom-sdk-button";

const QUESTIONS = [
  {
    question: "What is the perspective of the mom in the story?",
    options: [
      "A. She is reluctant to get a puppy because she would do all the work",
      "B. She is reluctant to get a puppy because she would have to send money",
      "C. She is reluctant to get a puppy because she prefer cats",
      "D. She is reluctant to get a puppy because she is afraid of dogs",
    ],
    answer: "A. She is reluctant to get a puppy because she would do all the work"
  },
  {
    question: "What evidence shows the children are prepared for a puppy?",
    options: [
      "A. They research puppy training and care",
      "B. They beg and plead with their mother",
      "C. They get jobs to raise money to buy the dog",
      "D. They research personalities of different breeds",
    ],
    answer: "A. They research puppy training and care"
  },
  {
    question: "The author uses the word reserves in the passage. Which phrase best completes the analogy \"Reserved is to active as _________\"",
    options: [
      "A. excitable is to energetic",
      "B. timid is to rowdy",
      "C. unruly is to naughty",
      "D. noisy is to loud",
    ],
    answer: "B. timid is to rowdy"
  },
  {
    question: "In paragraph 7, the author is described as feeling defensive of Buddy. What is the meaning of the word defensive?",
    options: [
      "A. wanting to capture Buddy",
      "B. wanting to tease Buddy",
      "C. wanting to protect Buddy",
      "D. wanting to admire Buddy",
    ],
    answer: "C. wanting to protect Buddy"
  },
  {
    question: "What is the little dogs reaction to Mom's words, \"Okay, Buddy, I guess you're it\"?",
    options: [
      "A. He gives a playful lap of his tongue",
      "B. He jumps up to greet the narrator",
      "C. He cocks his head to one side and flashes a smile",
      "D. He makes one ear stand up straighter",
    ],
    answer: "C. He cocks his head to one side and flashes a smile"
  },
  {
    question: "What is the main idea of the story?",
    options: [
      "A. following the process of adopting a new puppy",
      "B. researching the responsibilities of caring for a puppy",
      "C. convincing mom to allow the adoption of a new puppy",
      "D. determining the criteria for selecting a new puppy",
    ],
    answer: "C. convincing mom to allow the adoption of a new puppy"
  },
  {
    question: "From what point of view is the story told?",
    options: [
      "A. First person",
      "B. Second person",
      "C. Third person limited",
      "D. Third person omniscient",
    ],
    answer: "A. First person"
  }
]

const Interview = () => {
  var timerInterval;
  var sdkButton;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sdkButtonObject, setSdkButtonObject] = useState(null)
  const {user, isAuthenticated, isLoading} = useAuth0();
  const buttonRef = useRef();
  const {profile, accountId, answers, action} = useSelector(state => state.user, shallowEqual)
  const [questions, setQuestions] = useState([])
  const [sharedUrl, setSharedUrl] = useState("");
  const [isTestStarted, setIsTestStarted] = useState(0)
  const [currentParagraph, setCurrentParagraph] = useState(1);
  const [showStopRecordingModal, setShowStopRecordingModal] = useState(false)
  const [currentTime, setCurrentTime] = useState(0);
  const [currentQuestionStart, setCurrentQuestionStart] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [currentLoomStatus, setCurrentLoomStatus] = useState('');
  const [showStartButton, setShowStartButton] = useState(false);
  const testTimer = new Timer({label: 'test-timer'})
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const screenTransitions = useTransition(isTestStarted, {
      from: { position: 'absolute', opacity: 0 },
      enter: { opacity: 1, width: '100%' },
      leave: { opacity: 0 },
  })

  // console.log("SETUP", setup)

  const setCurrentTimerseconds = () => {
    timerInterval = setInterval(() => setCurrentTime(Math.floor(testTimer.ms()/1000)), 1000)
  }

  async function setupLoom() {
    try {  
      const { supported, error } = await isSupported();

      if (!supported) {
        // console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const button = document.getElementById(BUTTON_ID);

      if (!button || !setup) {
        return;
      }

      const SetupOutput = await setup({
        apiKey: API_KEY,
        config: {
          insertButtonText: "Save recording"
        }
      });

      sdkButton = SetupOutput.configureButton({ element: button });

      setSdkButtonObject(sdkButton)
      setShowStartButton(true)

      sdkButton.on("insert-click", video => {
        setSharedUrl(video.sharedUrl);
        onSubmitRecording(video)
      });

      sdkButton.on('recording-start', () => {
        setIsTestStarted(1)
        setCurrentTimerseconds()
         SpeechRecognition.startListening({ continuous: true });
        testTimer.start()
      });

      sdkButton.on('complete', () => {
        setIsTestStarted(0)
         SpeechRecognition.stopListening()
        testTimer.stop()
      });

      sdkButton.on('cancel', () => {
        setIsTestStarted(0)
        testTimer.clear()
         navigate('/dashboard')
      });

      sdkButton.on('lifecycle-update', state => {
      });
    }
    catch(err) {
      // console.log("LOOM SDK ERROR", err)
      // toast.error("Something went wrong! You will be taken to Dashboard automatically.")
      setTimeout(() => navigate('/dashboard'), 3000);
    }
  }

  useEffect(() => {
    if(!accountId) {
       navigate('/')
    }

    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };

    window.addEventListener('popstate', (e) => {
      window.open('/dashboard', '_self')
    })

    // dispatch(userActions.updateAnswerAction.action({questionNumber: 1, time: 0, question: QUESTIONS_ARRAY[0].title}))

    setupLoom();

    return () => {
      window.removeEventListener('popstate', () => null)
    }
  }, []);

  const onSubmitRecording = (video) => {
    setShowStopRecordingModal(false)
    SpeechRecognition.stopListening()
    navigate(`/postpitch?videoId=${video.id}`)
    // dispatch(userActions.saveAnswerAsyncActions.request({data: {answeredBy: accountId, videoId: video.id, createdOn: DateTime.now().toISO()}, callback: () => window.open('https://zoom.us/launch/chat?jid=robot_v1em0xtlwvts-bfb9xneacma@xmpp.zoom.us', '_self')}))
  }

  const onTestComplete = (element) => {
    // console.log("SUPPPPPEEEEER", sdkButtonObject?.endRecording, isTestStarted, element)
    if(sdkButtonObject?.endRecording)
      sdkButtonObject?.endRecording()
    else
      setShowStopRecordingModal(true)
  }

  const bestMatchSortFunction = (a, b) => {
    return parseFloat(a.match) - parseFloat(b.match)
  }

  const onNextQuestion = (element) => {
    const answerText = transcript.toLowerCase().slice(0, -1).replace(questions[currentQuestion], '')
    const answerMatches = QUESTIONS[currentQuestion-1].options.map(option => {
      return {option, match: stringSimilarityCheck(option, transcript)}
    })
    const bestMatch = answerMatches.sort(bestMatchSortFunction)[answerMatches.length - 1].option
    const options = QUESTIONS[currentQuestion-1].options.map(option => {
      const optionObject = {option, selected: option === bestMatch}
      if(option === QUESTIONS[currentQuestion-1].answer) {
        optionObject.correct = true
      }
      return optionObject;
    })
    const object = {questionNumber: currentQuestion, question: QUESTIONS[currentQuestion-1].question, time: currentQuestionStart, options}
    console.log("ANSWER", object)
    dispatch(userActions.updateAnswerAction.action(object))
    resetTranscript()
    setCurrentQuestionStart(currentTime)
    
    if(currentQuestion < 7) {
      setCurrentQuestion(p => p+1)
    }
    else {
      onTestComplete(element)
    }
  }

  if(!isTestStarted) {
    return (
      <RootContainer>
        <LogoImage src={Logo} />
        <BodyContainer>
          <MainText>Instructions (Reading comprehension)</MainText>
          <VerticalSpace count={3} />
          <TaglineText>Read each page <strong>aloud</strong></TaglineText>
          <TaglineText>When you have read the entire page, click <strong>Next Page →</strong></TaglineText>
          <TaglineText><strong>Please don't refresh the page or go back!</strong> Your recording will be lost.</TaglineText>
          <VerticalSpace count={4} />
          {
            showStartButton
            ?
            null
            :
            <SquareLoader size={35} color="#138DCA" />
          }
          <StartButton isVisible={showStartButton}  id={BUTTON_ID} ref={buttonRef}>Start Reading →</StartButton>
          <VerticalSpace count={1} />
          <NoteText>When you click on start you will be asked for camera and microphone permissions</NoteText>
        </BodyContainer>
      </RootContainer>
    )
  }

  if(isTestStarted === 1) {
    return (
      <RootContainer>
        <LogoImage src={Logo} />
        <BodyContainer>
          <MainText>Read aloud 👇</MainText>
          <VerticalSpace count={3} />
          <TextImage src={`images/P${currentParagraph}.jpeg`} />
          <VerticalSpace count={4} />
          {
            showStartButton
            ?
            null
            :
            <SquareLoader size={35} color="#138DCA" />
          }
          {
            currentParagraph === 1
            ?
            <ButtonRow>
              <StartButton isVisible={showStartButton} onClick={() => setCurrentParagraph(p => p+1)} >Next Page →</StartButton>
            </ButtonRow>
            :
            currentParagraph === 5
            ?
            <ButtonRow>
              <StartButton isVisible={showStartButton} onClick={() => setCurrentParagraph(p => p-1)}  >← Previous Page</StartButton>
              <HorizontalSpace count={4} />
              <StartButton isVisible={showStartButton} onClick={() => {setIsTestStarted(2); resetTranscript()}}  >Start Assesment →</StartButton>
            </ButtonRow>
            :
            <ButtonRow>
              <StartButton isVisible={showStartButton} onClick={() => setCurrentParagraph(p => p-1)}  >← Previous Page</StartButton>
              <HorizontalSpace count={4} />
              <StartButton isVisible={showStartButton} onClick={() => setCurrentParagraph(p => p+1)}  >Next Page →</StartButton>
            </ButtonRow>
          }
          {
            listening
            ?
            <p>Please <strong>don't</strong> refresh the page 🔄 or go back ⬅. Your recording will be lost!</p>
            :
            null
          }          
          <VerticalSpace count={1} />
        </BodyContainer>
      </RootContainer>
    )
  }

  if(isTestStarted === 2) {
    return (
      <RootContainer>
        <LogoImage src={Logo} />
        <BodyContainer>
          <MainText>Instructions (Assessment)</MainText>
          <VerticalSpace count={3} />
          <TaglineText>When you find the answer you believe is best, say the answer's letter and <strong>read the answer aloud</strong></TaglineText>
          <TaglineText>Please <strong>don't</strong> read the question or the answer options <strong>aloud</strong></TaglineText>
          <TaglineText>After you've answered the question, click <strong>Next Question →</strong></TaglineText>
          <TaglineText><strong>Please don't refresh the page or go back!</strong> Your assessment will be lost.</TaglineText>
          <VerticalSpace count={4} />
          {
            showStartButton
            ?
            null
            :
            <SquareLoader size={35} color="#138DCA" />
          }
          <StartButton isVisible={showStartButton} onClick={() => setIsTestStarted(3)} >Proceed to Questions →</StartButton>
          <VerticalSpace count={1} />
        </BodyContainer>
      </RootContainer>
    )
  }

  return (
    <RootContainer>
      <LogoImage src={Logo} />
      <BodyContainer>
        <MainText>When you find the answer you believe is best, say the answer's letter and read the answer aloud 👇</MainText>
        <TaglineText>Please <strong>don't</strong> read the question or all of the answer options <strong>aloud</strong></TaglineText>
        <VerticalSpace count={3} />
        <p id="iframe-container" align="center">
          <TextImage src={`images/Q${currentQuestion}.png`} />
        </p>
        <VerticalSpace count={1} />
          <PrimaryButton background="green" onClick={() => onNextQuestion(sdkButton)} >{currentQuestion < 7?'Next Question →':'End Practice'}</PrimaryButton>      
        {
          listening
          ?
          <p>Listening... (Please <strong>don't</strong> refresh the page 🔄 or go back ⬅. Your assessment will be lost!)</p>
          :
          null
        }
        <VerticalSpace count={10} />
        {
          showStopRecordingModal
          ?
          <Modal close={() => setShowStopRecordingModal(false)} >
            <MainText>Press stop (🟥) to preview and save recording</MainText>
            <TaglineText>
              Tip - If you can't find the stop button it's next to your video bubble below.
              <br/>
              <br/>
              ***********************************************************************
              <br/>            
              <b>Note</b> - Once you save, you will be redirected to a page where we will verify your email ID to send the deck slide.
            </TaglineText>
          </Modal>
          :
          null
        }
      </BodyContainer>
    </RootContainer>
  )

//   return (
//     <RootContainer>
//       <VerticalSpace count={2} />
//       {
//         isTestStarted
//         ?
//         <TestWrapper>
//           <TestHeading>Question {currentQuestion+1}: {questions[currentQuestion].title}</TestHeading>
//           <TestContainer>
//             <p align="center"><iframe src={questions[currentQuestion].link} title="Question" height="100%" style={{minWidth: "750px", minHeight: "450px", background: "black", borderRadius: "30px"}} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe></p>
//           </TestContainer>
//           {
//             listening
//             ?
//             <p>Listening</p>
//             :
//             null
//           }
//           <ButtonBar>
//             <PrimaryButton background="green" onClick={() => onNextQuestion(sdkButton)} >{currentQuestion < questions.length-1?'Next Question →':'End Video Pitch'}</PrimaryButton>
//           </ButtonBar>
//         </TestWrapper>
//         :
//         <TestWrapper>
//           <TestHeading>{profile.name}, please read our recommendations below for best experience 👇</TestHeading>
//           <TestContainer>
//             When you click on start you will get to choose how you want to record (you need to give Loom - camera and microphone permissions).
//             <br/>
//             <br/>
//             Click on "Start Video Pitch" before reading further..
//             <br/>
//             <br/>
//             1. We recommend you select "Screen and Camera" and use computer speakers (no headphones 🚫🎧) so that we can record both the question and answer.
//             <br/>
//             2. If possible, increase the video bubble size by hovering over the bubble and clicking "..." to choose the larger bubble (⚫). 
//             <br/>
//             3. Sharing "Screen and Camera" with larger video bubble is helpful when you want to share the recording with others to get feedback.
//             <br/>
//             <br/>
//             Start when you are ready! <b>Take a deep breath!</b>
//           </TestContainer>
//           <ButtonBar>
//           {
//             showStartButton
//             ?
//             <PrimaryButton id={BUTTON_ID} background="green" ref={buttonRef} >Start Video Pitch →</PrimaryButton>
//             :
//             null
//           }
//           </ButtonBar>
//         </TestWrapper>
//       }
//       {
//         showStopRecordingModal
//         ?
//         <Modal close={() => setShowStopRecordingModal(false)} >
//           <ModalTitle>Press stop (🟥) to preview and save recording</ModalTitle>
//           <ModalContent>
//             Tip - If you can't find the stop button it's next to your video bubble below.
//             <br/>
//             <br/>
//             ***********************************************************************
//             <br/>            
//             <b>Note</b> - Once you save, you will be redirected to our PrepAir chatbot. Click on "View recording" to watch your latest recording. Feel free to share it with others.
//           </ModalContent>
//         </Modal>
//         :
//         null
//       }
// {/*      {
//         /         ?
//         <Modal close={() => null} >
//           <ModalTitle>Recording saved! Redirecting to our PrepAir app in 3 seconds...</ModalTitle>
//           <ModalContent>If signed in, this video will be saved to your loom account. Click on "View recording" to watch your latest recording. Feel free to share it with others.</ModalContent>
//         </Modal>
//         :
//         null
//       }*/}
//     </RootContainer>
//   );
}

export default Interview;
