// https://coolors.co/2d7dd2-679436-ccdbdc-f24236

import styled from 'styled-components';

export const RootContainer = styled.div`
	max-width: 100%;
	min-height: 100%;
`;

export const ParticipantWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: ${({theme}) => theme.colors.halfWhite};
	margin: 15px;
	width: calc(100% - 60px);
	padding: 15px;
`;

export const ParticipantContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const ParticipantEmail = styled.div`
	color: ${({theme}) => theme.colors.black};
	font-size: 14px;
`;

export const ParticipantName = styled(ParticipantEmail)`
	font-weight: bold;
	line-height: 22px;
`;

export const InstructionWrapper = styled(ParticipantWrapper)`
	display: block;
`;

export const InstructionsHeading = styled.h2`
	text-align: center;
`;

export const InstructionsContainer = styled.div`
	margin: 10px;
	padding: 10px;
	background-color: ${({theme}) => theme.colors.white};
`;

export const InstructionList = styled.ul`
	display: block;
`;

export const InstructionListItem = styled.li`
	font-size: 14px;
`;

export const ButtonBar = styled.div`
	display: flex;
	padding: 15px;
	justify-content: center;
`;
