import {useState, useEffect} from 'react';
import {
	RootContainer,
	VideoContainer,
	QuestionContainer,
	QuestionCard,
	QuestionTitle,
	QuestionTime,
} from './Recording.styles.js';
import {PrimaryButton, VerticalSpace} from 'components';
import { oembed } from "@loomhq/loom-embed";
import {userActions} from 'modules/user';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import useQuery from 'utils/useQuery';

const Recording = () => {
	const {id} = useParams()
	const query = useQuery()
	const dispatch = useDispatch()
	const {allAnswers} = useSelector(state => state.user, shallowEqual)
	const [videoHTML, setVideoHTML] = useState("");

	const getAlexaUsername = async () => {
		const alexaUserName = prompt('Enter your Alexa unique ID below (You should have used our Alexa skill app). For more info contact - ak@prepair.ai')
		// console.log("ALEXA USERID ", alexaUserName);
		const alexaUser = await axios.get(`https://zbot.brainsprays.com/prezoom/getAlexaAccount/${alexaUserName}`)
		// console.log("ALEXA USER DATA", alexaUser.data)
		window.open(alexaUser.data.result.lastAnswer.objectURL, "_blank")
	}

	useEffect(() => {
		dispatch(userActions.getAllAnswersAsyncActions.request({data: id, action: query.get('action') || 'pitchdeck', callback: () => null}))
	}, [])

	useEffect(() => {
		const getLoomEmbed = async (videoId) => {
			// console.log("LOOM EMBED", ((window.innerWidth/4)*3))
			const { html } = await oembed(`https://www.loom.com/share/${videoId}`, {height: window.innerHeight});
        	setVideoHTML(html);
		}
		if(allAnswers.length) {
			getLoomEmbed(allAnswers[0].videoId)
		}
	}, [allAnswers])

	return (
		<RootContainer>
			<VideoContainer dangerouslySetInnerHTML={{ __html: videoHTML }} >
			</VideoContainer>
			<QuestionContainer>
				{
					allAnswers?.length
					?
					allAnswers[0]?.answers?.map(answer => (
						<QuestionCard>
							<QuestionTitle>Q{answer.questionNumber}: {answer.question}</QuestionTitle>
							<QuestionTime>{moment.duration(answer.time*1000).minutes()} min {moment.duration(answer.time*1000).seconds()} secs</QuestionTime>
						</QuestionCard>
					))
					:
					"No recording found..."
				}
				<VerticalSpace count={3} />
				<PrimaryButton background="green" onClick={() => window.open(allAnswers[0].objectURL, "_blank")}>Get your pitch deck</PrimaryButton>
				<VerticalSpace count={3} />
				<PrimaryButton background="blue" onClick={getAlexaUsername}>Get pitch deck from Alexa</PrimaryButton>
			</QuestionContainer>
		</RootContainer>
	)
}

export default Recording;
